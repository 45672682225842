import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';

import * as log from 'src/utils/logger';
import { useClaimInfoStore } from 'src/stores/claimInfo';

interface IDamageProps {
  buttonRight?: JSX.Element;
  hideRestart?: boolean;
  hideBack?: boolean;
  variant?: 'primary' | 'secondary';
  handleBack?: () => void;
}

export interface IRestartDialogProps {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}

const DamageHeader: React.FC<IDamageProps> = (props) => {
  const {
    buttonRight,
    hideRestart,
    hideBack,
    handleBack,
    variant = 'primary',
  } = props;
  const navigate = useNavigate();
  const { claimId, captureType, roomId } = useParams();
  const [restartDialog, setRestartDialog] = useState(false);
  const isRecapture = useClaimInfoStore(
    (state) => state.recaptureInfo?.isRecapture
  );

  const handleBackAction = () => {
    log.event({ event: 'Back Clicked', data: { claimId, roomId } });
    if (typeof handleBack === 'function') {
      handleBack?.();
    } else {
      navigate(-1);
    }
  };

  const _handleRestart = async () => {
    setRestartDialog(false);
    log.event({ event: 'Restart Clicked', data: { claimId, roomId } });
    await dexieDb.pictures.clear();
    if (isRecapture) navigate(`/${captureType}/${claimId}/${roomId}/recapture`);
    else navigate(`/${captureType}/${claimId}`);
  };

  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          boxShadow: 'none',
          zIndex: 100,
        }}
        elevation={1}
      >
        <div
          className={classNames('grid grid-cols-3 h-12 max-w-md', {
            'bg-screen-bg-primary': variant === 'primary',
            'bg-screen-bg-secondary': variant === 'secondary',
          })}
        >
          <div className="justify-self-start self-center">
            {!hideBack && (
              <Button
                style={{ textTransform: 'capitalize' }}
                onClick={handleBackAction}
                className="text-sm !text-icons-color p-2"
                variant="text"
                data-testid="header-back-btn"
                startIcon={<ChevronLeftIcon />}
              >
                Back
              </Button>
            )}
          </div>
          <div className="justify-self-center self-center"></div>
          <div className="justify-self-end self-center">
            {!buttonRight && !hideRestart && (
              <Button
                onClick={() => setRestartDialog(true)}
                style={{ textTransform: 'capitalize' }}
                data-testid="header-restart-btn"
                className="text-sm !text-icons-color p-2"
                variant="text"
              >
                Restart
              </Button>
            )}
            {buttonRight}
          </div>
        </div>
      </Paper>
      {!buttonRight && !hideRestart && (
        <RestartDialog
          open={restartDialog}
          onClose={() => setRestartDialog(false)}
          onDone={_handleRestart}
        />
      )}
    </>
  );
};

export default DamageHeader;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RestartDialog: React.FC<IRestartDialogProps> = (props) => {
  const { open, onClose, onDone } = props;

  const handleClose = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      id="header-restart-dialog"
      data-testid="header-restart-dialog"
    >
      <div className="p-8 mx-auto">
        <div style={{ maxWidth: '270px' }}>
          <h3 className="text-base font-semibold">
            Are you sure you want to restart the capture for this room ?
          </h3>
          <div className="flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop mt-4">
            <BaseButton
              label="Yes, restart"
              dataTestId="yes-restart-btn"
              className="w-full m-1 bg-red-500 desktops:w-[70%] mx-auto"
              onClick={onDone}
              data-testid="header-restart-dialog-yes-action"
            />
          </div>
          <div className="flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop">
            <BaseButton
              label={`Don't restart`}
              className="w-full m-1 desktops:w-[70%] mx-auto"
              onClick={handleClose}
              data-testid="header-restart-dialog-no-action"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
