import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useFeatureStore } from 'src/stores/featureStore';
import { analytics } from 'src/texts/texts';
import { useLocalStorage } from 'usehooks-ts';

import BaseButton from 'src/components/BaseButton/BaseButton';
import BrandingHeader from 'src/components/BrandingHeader/BrandingHeader';
import ClaimInfo from './components/ClaimInfo/ClaimInfo';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import Text from 'src/components/Text/Text';
import InstructionalVideo from 'src/components/InstructionalVideo/InstructionalVideo';

import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';
import TextField from '@mui/material/TextField';
import { isValidEmailOrPhone } from 'src/utils/utils';

interface LandingProps {
  skipQuestions?: boolean;
}

const LandingPage = ({ skipQuestions }: LandingProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { claimId, captureType, returnId } = useParams();
  const [cachedRoomId] = useLocalStorage<string | null>('cachedRoomId', null);

  useLocalStorage('skipQuestions', !!skipQuestions);
  useLocalStorage('test', captureType === 'test');

  const navigate = useNavigate();
  const claimInfo = useClaimInfoStore();
  const setReturnId = useClaimInfoStore((state) => state.setReturnId);
  const setStateContactInfo = useClaimInfoStore(
    (state) => state.setContactInfo
  );
  const landingCopy = useEntityConfigStore((state) => state.copy.landing);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const instructionalVideo = useFeatureStore(
    (state) => state.instructionalVideo
  );
  const collectContactInfo = useFeatureStore(
    (state) => state.collectContactInfo
  );
  const [contactInfo, setContactInfo] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const isContactInfoReq =
    claimInfo?.userType === 'xactimate' && collectContactInfo;
  const isValid = isContactInfoReq ? isValidEmailOrPhone(contactInfo) : true;

  useEffect(() => {
    if (cachedRoomId && navigator.onLine) {
      localStorage.removeItem('cachedRoomId');
      navigate(`/${captureType}/${claimId}/${cachedRoomId}/confirm-photos`, {
        relative: 'path',
      });
    }
  }, [cachedRoomId, captureType, claimId, navigate]);

  useEffect(() => {
    setReturnId(returnId);
  }, [returnId, setReturnId]);

  const navigateToRoomSelect = () => {
    navigate(`/${captureType}/${claimId}/room-select`, {
      relative: 'path',
    });
  };

  const handleClick = () => {
    log.event({
      event: eventNames.START_PROCESS,
      data: { page: 'Landing Page', env: process.env.REACT_APP_ENV },
    });
    if (contactInfo !== '') setStateContactInfo(contactInfo);
    if (instructionalVideo) {
      setOpen(true);
    } else {
      navigateToRoomSelect();
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setContactInfo(evt.target.value);
  };

  return (
    <ContentView
      className="gap-2 min-h-screen -mt-14 pt-14 landscape:pb-24"
      variant="secondary"
    >
      <>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-center pb-8">
            <BrandingHeader
              logo={claimInfo?.logo || ''}
              logoVerified={claimInfo?.logoVerified}
              hideHostaBranding={entityConfig.hideHostaBranding}
            />
            <div className="pb-3">
              <Text variant="title">Document your claim</Text>
            </div>
            <Text testId="landing-subtitle" variant="subtitle">
              {landingCopy?.subtitle?.supplant({
                displayName:
                  claimInfo?.displayName || 'Your Insurance provider',
              })}
            </Text>
          </div>

          <ClaimInfo
            claimId={claimInfo?.customerId || claimInfo?.claimId}
            address={claimInfo?.claimInfo}
          />
          {isContactInfoReq && (
            <div className="flex flex-col w-full gap-2">
              <Text extraClassname="text-justify" variant="title">
                Contact
              </Text>
              <Text extraClassname="text-justify" variant="paragraph">
                Please give us your contact information so we can notify you of
                successful claim submissions.
              </Text>
              <TextField
                value={contactInfo}
                onChange={handleChange}
                error={!isValid && isDirty}
                helperText={
                  !isValid && isDirty
                    ? 'Please enter a Valid Email or Phone'
                    : ''
                }
                placeholder="Phone or email address"
              />
            </div>
          )}
        </div>

        <Footer showAppVersion>
          <BaseButton
            disabled={!isValid}
            dataTestId="start-process"
            name={analytics.beginButton}
            onClick={handleClick}
            label={instructionalVideo ? 'See how this works' : 'start process'}
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        </Footer>
      </>
      <InstructionalVideo
        isOpen={open}
        handleAction={navigateToRoomSelect}
        actionLabel="Get Started"
      />
    </ContentView>
  );
};

export default LandingPage;
