import { useEntityConfigStore } from 'src/stores/entityConfig';
import Text from 'src/components/Text/Text';
import BaseButton from 'src/components/BaseButton/BaseButton';
import RetakeCamera from 'src/components/icons/common/RetakeCamera';

import type { ReactElement } from 'react';

type Props = {
  title: string;
  subtitle: string;
  icon: ReactElement;
  image?: string | null;
  imageType?: string | null;
  handleCapture?: (data: string) => void;
};

const PhotoCapture = ({
  title,
  subtitle,
  icon,
  image,
  imageType,
  handleCapture,
}: Props) => {
  const entityConfig = useEntityConfigStore((state) => state.config);
  return (
    <>
      <Text variant="title">{title}</Text>
      <Text variant="subtitle" extraClassname="mt-3 mb-4">
        {subtitle}
      </Text>
      {image === null && <div className="w-[60%]">{icon}</div>}
      {image !== null && (
        <div className="px-4 pt-4 mb-20 w-full">
          <h5 className="font-medium py-4">{`Your ${imageType} photo:`}</h5>
          <img
            alt="Capture"
            className="rounded-lg w-full m-auto max-h-[300px]"
            src={image}
          />
          <BaseButton
            type="capture"
            onCapture={handleCapture}
            contained
            weight="normal"
            className="text-icons-color"
            icon={<RetakeCamera className="fill-icons-color" />}
            label="retake photo"
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        </div>
      )}
    </>
  );
};

export default PhotoCapture;
