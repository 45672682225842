// import {withSentryReactRouterV6Routing} from '@sentry/react';
import React, { useEffect, PropsWithChildren } from 'react';
import {
  Outlet,
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import GenericError from 'src/components/ErrorFallback/Generic';

import * as log from 'src/utils/logger';

import CaptureRootPage from 'src/pages/CaptureRootPage/CaptureRootPage';
import ConfirmPhotosPage from 'src/pages/ConfirmPhotosPage/ConfirmPhotosPage';
import DamageExample from 'src/pages/Damage/DamageExample/DamageExample';
import LandingPage from 'src/pages/LandingPage/LandingPage';
import DamageCapture from 'src/pages/Damage/DamageCapture/DamageCapture';
import RoomCapture from 'src/pages/Damage/RoomCapture/Index';
import RoomSelectPage from 'src/pages/RoomSelect/RoomSelectPage';
import RoomSubmittedPage from 'src/pages/RoomSubmittedPage/RoomSubmittedPage';
import VideoTipsPage from 'src/pages/VideoTipsPage/VideoTipsPage';
import SurveyPage from 'src/pages/SurveyPage/SurveyPage';
import RecaptureRootPage from 'src/pages/CaptureRootPage/RecaptureRootPage';
import RecaptureLanding from 'src/pages/LandingPage/RecaptureLanding';

// const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const RedirectPage = () => {
  const navigate = useNavigate();
  const { claimId, captureType } = useParams();

  useEffect(() => {
    if (claimId !== 'damage') navigate(`/${captureType}/${claimId}`);
  }, [claimId, captureType, navigate]);

  return <></>;
};

const ErrorElement = ({ children }: PropsWithChildren) => (
  <ErrorBoundary
    fallback={<GenericError />}
    onError={(error, info) => {
      log.error({ event: 'Rendering error', data: { error, info } });
    }}
  >
    {children}
  </ErrorBoundary>
);

const RoomCaptureRouter: React.FunctionComponent = () => {
  return (
    <ErrorElement>
      <Routes data-testid="room-capture-router">
        <Route
          path=":captureType/:claimId/:returnId?"
          element={<CaptureRootPage />}
          errorElement={<ErrorElement />}
        >
          <Route
            path=""
            element={<LandingPage />}
            errorElement={<ErrorElement />}
          />
          <Route
            path="room-select"
            element={<RoomSelectPage />}
            errorElement={<ErrorElement />}
          />
          <Route path=":roomId" element={<Outlet />}>
            <Route
              path="damage-example"
              element={<DamageExample />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="damage"
              element={<DamageCapture />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="tips"
              element={<VideoTipsPage />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="capture-room"
              element={<RoomCapture />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="confirm-photos"
              element={<ConfirmPhotosPage />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="room-submitted"
              element={<RoomSubmittedPage />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="feedback"
              element={<SurveyPage />}
              errorElement={<ErrorElement />}
            />
          </Route>
        </Route>
        <Route
          path=":claimId"
          element={<RedirectPage />}
          errorElement={<ErrorElement />}
        />
        <Route
          path=":captureType/:claimId/:roomId/recapture"
          element={<RecaptureRootPage />}
          errorElement={<ErrorElement />}
        >
          <Route
            path=""
            element={<RecaptureLanding />}
            errorElement={<ErrorElement />}
          />
        </Route>
      </Routes>
    </ErrorElement>
  );
};

export default RoomCaptureRouter;
