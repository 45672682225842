export const eventNames = {
  START_PROCESS: 'Start Process',
  SELECT_ROOM: 'Select Room',
  ROOM_SELECTED_SUCCESS: 'Room Type Selection Success',
  ROOM_SELECTED_FAIL: 'Room Type Selection Error',
  START_DAMAGE: 'Start Damage Capture',
  CLOSE_UP_PHOTO: 'Close Up Photo',
  CONTEXT_PHOTO: 'Context Photo',
  RETAKE_PHOTO: 'Retake Photo',
  ADD_MORE_DAMAGE: 'Add More Damage',
  DONE_WITH_DAMAGE: 'Done With Damage',
  START_ROOM: 'Start Room Capture',
  BACK: 'Clicked on Back',
  RESTART: 'Clicked on Restart',
  NEXT_TIP: 'Next Tip',
  SKIP_TIPS: 'Skip Tips',
  TAKE_WALL_PHOTO: 'Take Wall Photo',
  CONFIRM_GOOD_PHOTO: 'Confirm Good Photo',
  CONFIRM_BAD_PHOTO: 'Confirm Bad Photo',
  RETAKE_ONE_PHOTO: 'Retake With 1 Photo',
  RETAKE_TWO_PHOTO: 'Retake With 2 Photos',
  COMPLETE_ROOM_CAPTURE: 'Complete Room Capture',
  ADD_ANOTHER_ROOM: 'Add Another Room',
  DONE_WITH_ROOM: 'Done with Room',
  SUBMIT_ROOM_SUCCESS: 'Room Submitted',
  SUBMIT_ROOM_FAIL: 'Failed to Upload',
  COMPLETE_ROOM: 'Complete Process',
  SURVEY_FEEDBACK: 'Survey Feedback',
  RETURN_TO_URL: 'Return to URL',
};
