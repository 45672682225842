import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getImageLink } from 'src/REST/capture';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useImageStore } from 'src/stores/image';

import { dexieDb, handleError } from 'src/utils/indexedDb';

import { error, info } from 'src/utils/logger';
import { base64ToFile, getBase64StringFromDataURL } from 'src/utils/utils';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import InstructionTitle from 'src/components/InstructionTitle/InstructionTitle';
import RetakePhoto from '../SmallRoom/RetakePhoto';
import MissingEdges from './MissingEdges';

type Props = {
  imageNumber: number;
  imageData: string;
  onComplete: () => void;
  handleCancel?: () => void;
};

const BrowserAssistedCapture = ({
  imageNumber,
  imageData,
  onComplete,
  handleCancel,
}: Props) => {
  const [show, setShow] = useState(false);
  const initState = {
    loading: false,
    success: false,
    error: false,
    summary: false,
    failed: false,
  };
  const [condition, setCondition] = useState(initState);
  const { claimId, roomId } = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const aiModel = useImageStore((state) => state.model);
  const modelLoaded = useImageStore((state) => state.modelLoaded);
  const entityConfig = useEntityConfigStore((state) => state.config);

  useEffect(() => {
    if (!modelLoaded) {
      console.log('model not loaded yet');
    }
  }, [modelLoaded]);

  useEffect(() => {
    setCondition({ ...initState, loading: true });
    // Handle Images
    analyzeImage(imageData);
    // eslint-disable-next-line
  }, [imageData]);

  const analyzeImage = async (imageData: string) => {
    try {
      const image = new Image();
      image.src = imageData;
      await image.decode();
      const start = performance.now();
      const result = await aiModel.segmentImage(image);
      const end = performance.now();
      if (result && result.length > 0) {
        setErrors(result);
        setCondition({ ...initState, summary: true });
      } else {
        setErrors([]);
        setCondition({ ...initState, success: true });
        handleComplete();
      }
      await dexieDb.pictures
        .update(imageNumber, { errors: result })
        .catch(handleError);
      info({
        event: 'Browser Image Feedback',
        data: { imageNumber, errors: result, time: end - start },
      });
    } catch (ex) {
      error({
        event: 'Browser Image Feedback Fail',
        data: { imageNumber, error: ex, stringError: JSON.stringify(ex) },
      });
      setCondition({ ...initState, failed: true });
    }
  };

  const handleComplete = () => {
    setTimeout(() => {
      setCondition(initState);
      onComplete();
    }, 1000);
  };

  const handleRetake = (imageData: string) => {
    setShow(false);
    setCondition({ ...initState, loading: true });
  };

  const getDirections = (errors: string[]): string[] => {
    const directions = ['top', 'bottom', 'left', 'right'];
    let result: string[] = [];
    directions.forEach((e) => {
      errors.forEach((x) => {
        if (x.includes(e)) result.push(e);
      });
    });
    return result;
  };

  const retakeImage = async () => {
    setShow(true);
    if (claimId && roomId) {
      const file = base64ToFile(getBase64StringFromDataURL(imageData));
      getImageLink(
        claimId,
        roomId,
        file,
        'jpeg',
        imageNumber.toString(),
        'rejectedImages',
        undefined,
        undefined,
        {},
        errors
      )
        .then((uploadFields: any) => {
          info({
            event: 'Browser Image Feedback - Rejected Image',
            data: { imageNumber, errors, imageId: uploadFields?.key },
          });
        })
        .catch((error) => {
          error({
            event: 'Browser Image Feedback - Rejected Image Upload Fail',
            data: { imageNumber, errors, error },
          });
        });
    }
  };

  return (
    <>
      {show && (
        <RetakePhoto
          imageNumber={imageNumber}
          onComplete={onComplete}
          handleBack={() => setShow(false)}
          onRetake={handleRetake}
        />
      )}
      {condition.loading && (
        <div className="flex w-full h-screen flex-col justify-center items-center gap-2">
          <CircularProgress />
          <div>
            <h6 className="font-medium">Your photo(s) are being analyzed</h6>
            <p>This will take around 5 seconds</p>
          </div>
        </div>
      )}
      {condition.error && (
        <div className="flex w-full h-screen flex-col justify-center items-center gap-2">
          <Cancel fontSize="large" className="text-red-500" />
          <div>
            <h6 className="font-medium">Your photo(s) failed.</h6>
          </div>
        </div>
      )}
      {condition.success && (
        <div className="flex w-full h-screen flex-col justify-center items-center gap-2">
          <CheckCircle fontSize="large" className="text-green-500" />
          <div>
            <h6 className="font-medium">
              Your photo(s) was captured successfully.
            </h6>
          </div>
        </div>
      )}
      {condition.failed && (
        <ContentView>
          <div className="flex w-full h-screen flex-col justify-center items-center gap-2">
            <InstructionTitle
              className="text-center"
              text="Unfortunately, we weren't able to analyse your photo"
            />
            <div>
              <h6 className="font-medium text-center">
                Please try again. If you are not able to proceed please reach
                out to Hosta support.
              </h6>
            </div>
            <Footer>
              <BaseButton label="Skip" onClick={onComplete} />
            </Footer>
          </div>
        </ContentView>
      )}
      {condition.summary && !show && (
        <div className="w-full">
          <DamageHeader handleBack={() => handleCancel?.()} />
          <ContentView className="pb-48">
            <div className="py-2">
              <MissingEdges
                directions={getDirections(errors)}
                errors={errors}
                image={imageData}
              />
            </div>
            <Footer extraClassname="bg-white pt-4">
              <>
                <BaseButton
                  label="continue to retake photo"
                  onClick={retakeImage}
                  capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                />
                <BaseButton
                  outlined
                  dataTestId="skip-retake-wall-photo"
                  label="skip (my wall photo shows every edge)"
                  onClick={onComplete}
                  capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                />
              </>
            </Footer>
          </ContentView>
        </div>
      )}
    </>
  );
};

export default BrowserAssistedCapture;
