import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import useValidateLink from 'src/hooks/useValidateLink/useValidateLink';
import useEntity from 'src/hooks/useEntity/useEntity';
import { useImageStore } from 'src/stores/image';
import * as log from 'src/utils/logger';
import mixpanel from 'mixpanel-browser';
import Modal from 'src/components/Modal/Modal';
import Loading from 'src/components/Loading/Loading';
import flagsmith from 'flagsmith';
import { useFeatureStore } from 'src/stores/featureStore';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import { shallow } from 'zustand/shallow';
import { uploadRoomImage } from 'src/utils/uploadImage';
import * as Sentry from '@sentry/react';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { getRecaptureInfo } from 'src/REST/capture';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

const CaptureRootPage = () => {
  const [time, setTime] = useState(new Date().getTime());
  const [page, setPage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { claimId, roomId, captureType, linkId } = useParams();
  const [, setIsTest] = useLocalStorage('test', false);
  const { error, data } = useValidateLink(linkId || claimId || 'not-found');
  const loaded = useEntity(data?.entity);
  const model = useImageStore((state) => state.model);
  const modelLoaded = useImageStore((state) => state.modelLoaded);
  const setModelLoaded = useImageStore((state) => state.setModelLoaded);
  const aiAssist = useFeatureStore((state) => state.aiAssist);
  const toggleDamage = useFeatureStore((state) => state.toggleDamage);
  const newSubmitLoader = useFeatureStore((state) => state.newSubmitLoader);
  const recaptureInfo = useClaimInfoStore((state) => state.recaptureInfo);
  const setRoomInfo = useClaimInfoStore((state) => state.setRecaptureInfo);

  // Show Failure Alert
  const [open, setOpen] = useState<string | null>(null);
  const [processingError, setProcessingError] = useState(false);

  useEffect(() => {
    const confirmPhotos =
      !location.pathname.includes('confirm-photos') && newSubmitLoader;
    if (
      claimId &&
      roomId &&
      !recaptureInfo?.isRecapture &&
      !location.pathname.includes('room-submitted') &&
      !location.pathname.includes('feedback')
    ) {
      getRecaptureInfo(claimId, roomId)
        .then(({ data }) => {
          setRoomInfo({
            rejectionCount: data?.roomInfo?.rejectionCount,
            roomType: data?.roomInfo?.roomType,
            isRecapture: data?.roomInfo?.status === 'Not Processable',
            status: data?.roomInfo?.status,
          });
          if (data?.roomInfo?.status === 'Processing' && confirmPhotos) {
            setProcessingError(true);
          } else if (data?.roomInfo?.status === 'Not Processable') {
            navigate(`/${captureType}/${claimId}/${roomId}/recapture`);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, [
    claimId,
    roomId,
    recaptureInfo?.isRecapture,
    navigate,
    captureType,
    location.pathname,
    newSubmitLoader,
    setRoomInfo,
  ]);

  useEffect(() => {
    let isUploadInProgress = false;
    const recaptureKey = recaptureInfo?.isRecapture
      ? 'RECAPTURE_' + recaptureInfo?.rejectionCount
      : undefined;
    const handleEvent = async (events: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [firstInLine, ...remainingEvents] = events || [];

      if (!isUploadInProgress && firstInLine && claimId && roomId) {
        isUploadInProgress = true;
        try {
          await uploadRoomImage({
            roomId,
            claimId,
            imgId: firstInLine.id,
            abortController: firstInLine.abortController,
            recaptureKey,
          });
        } catch (e: any) {
          const erroMessage =
            typeof e?.response?.data === 'string'
              ? e?.response?.data
              : 'Upload Failed due to Internal Error';
          setOpen(erroMessage);
          Sentry.captureException(e);
        } finally {
          isUploadInProgress = false;
          // Remove the processed event from the queue
          useUploadQueueStore.getState().remove(firstInLine);
        }
      }
    };

    return useUploadQueueStore.subscribe(
      (state) => state.eventQueue,
      handleEvent,
      {
        equalityFn: shallow,
        fireImmediately: false,
      }
    );
  }, [claimId, roomId, recaptureInfo]);

  useEffect(() => {
    if (data) toggleDamage(data.allowDamage);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!modelLoaded && aiAssist) {
      (async () => {
        await model.loadModel();
        setModelLoaded(true);
      })();
    }
  }, [modelLoaded, setModelLoaded, model, aiAssist]);

  useEffect(() => {
    setIsTest(captureType === 'test');
    // eslint-disable-next-line
  }, [captureType]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const timeSpent = (currentTime - time) / 1000;
    setTime(currentTime);
    setPage(location.pathname);
    log.info({
      event: 'Time Spent',
      data: { pageName: page.split('/').pop() || page, timeSpent },
    });
    mixpanel.track(page.split('/').pop() || page, {
      timeSpent,
      claimId,
      roomId,
    });
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (data) {
      if (flagsmith.initialised) {
        mixpanel.identify(data.submitter);
        Sentry.setUser({ email: data.submitter });
        mixpanel.people.set_once('$email', data.submitter);
        flagsmith.identify(data.submitter);
        flagsmith.setTraits({
          enableAIAssistance: data.enableAIAssistance,
          allowDamage: data.allowDamage,
          displayName: data.displayName,
          environment: process.env.REACT_APP_ENV || 'LOCAL',
          entity: data.entity,
          submitter: data.submitter,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (sessionStorage.getItem('did_crash') === 'true') {
      log.error({
        event: 'Application Crash',
        data: { claimId: claimId, roomId: roomId },
      });
      sessionStorage.removeItem('did_crash');
      localStorage.setItem('disable_feedback', 'true');
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => setOpen(null);

  const handleAddAnotherRoom = () => {
    navigate(`/${captureType}/${claimId}/room-select`);
    setProcessingError(false);
  };

  return (
    <>
      {loaded || error !== null ? <Outlet /> : null}
      {error ? <Modal noCloseHandlers title="Caution" text={error} /> : null}
      {processingError ? (
        <Modal
          noCloseHandlers
          title="Room processing in progress"
          text="This is room is currently in processing state. We will notify you once it is ready."
          buttonText="Add Another Room"
          handleClose={handleAddAnotherRoom}
        />
      ) : null}
      <Loading open={!loaded && error === null} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        open={Boolean(open)}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {open}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CaptureRootPage;
