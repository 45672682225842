// @ts-nocheck
import HomePageRouter from './routers/HomePageRouter';
import RoomCaptureRouter from './routers/RoomCaptureRouter';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import flagsmith from 'flagsmith';
import { useFeatureStore } from './stores/featureStore';
import { useLocalStorage } from 'usehooks-ts';

function App() {
  const [isLoad, setIsLoad] = useState(false);
  const [isReturningUser] = useLocalStorage('returningUser', false);
  const features = useFeatureStore();

  useEffect(() => {
    // Init Feature Toggle
    flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_ID || '',
      onChange: () => {
        features.toggleAiAssist(flagsmith.hasFeature('ai_assistance'));
        features.toggleTipsVisible(
          !(
            flagsmith.hasFeature('hide_tips_returning_users') &&
            Boolean(isReturningUser)
          )
        );
        features.toggleCollectContactInfo(
          flagsmith.hasFeature('collect_contact_info')
        );
        features.toggleCollectRoomName(
          flagsmith.hasFeature('collect_room_info')
        );
        features.toggleInBrowserCapture(
          flagsmith.hasFeature('in_browser_capture')
        );
        // Check for returning User
        features.toggleInstructionalVideo(
          flagsmith.hasFeature('instructional_video') &&
            !(
              flagsmith.hasFeature('hide_tips_returning_users') &&
              Boolean(isReturningUser)
            )
        );
        features.toggleNewSubmitLoader(
          flagsmith.hasFeature('new_submit_loader')
        );
        // features.toggleDamage(flagsmith.hasFeature('enable_damages'));
      },
    });
    // Init Mix Panel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID || '', {
      debug: true,
      track_pageview: 'full-url',
    });
    mixpanel.set_config({ persistence: 'localStorage' });
    setTimeout(() => {
      setIsLoad(true);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  if (!isLoad) return <div />;

  return (
    <div className="capture-app min-h-full mx-auto">
      <HomePageRouter />
      <RoomCaptureRouter />
    </div>
  );
}

export default Sentry.withProfiler(App, { name: 'WebCapture' });
