import { useEntityConfigStore } from 'src/stores/entityConfig';
import DamageHeader from 'src/components/Header/DamageHeader';
import ContentView from 'src/components/Content/Content';
import Text from 'src/components/Text/Text';
import TakePhotoFooterButton from 'src/components/TakePhotoFooterButton';
import React from 'react';

type Props = {
  handleComplete: (imgData: string[]) => void;
  handleCancel: () => void;
};

const OnePhotoCapture = ({ handleComplete, handleCancel }: Props) => {
  const entityConfig = useEntityConfigStore((state) => state.config);
  const captureImageCopy = useEntityConfigStore(
    (state) => state.copy.captureImage
  );
  return (
    <>
      <DamageHeader handleBack={handleCancel} />
      <ContentView>
        <>
          <Text
            variant="title"
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          >
            retake wall photo
          </Text>
          <Text variant="subtitle" extraClassname="pt-8 pb-10">
            {captureImageCopy.retake.onePhoto.subtitle}
          </Text>

          <img
            alt="capture tip"
            className="w-full rounded-lg"
            src="/tips/tip_03.gif"
          />

          <TakePhotoFooterButton
            label="take wall photo"
            eventName="TODO: create event name"
            onCapture={(data) => handleComplete([data])}
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        </>
      </ContentView>
    </>
  );
};

export default OnePhotoCapture;
