import Dexie, { Table } from 'dexie';
import * as Sentry from '@sentry/react';

export interface IPicture {
  id: number;
  content: string;
  roomId?: string;
  extraImages?: Array<string>;
  moreImages?: Array<number>;
  imgType?: string;
  damageId?: string;
  wallNumber?: number;
  uploaded?: boolean;
  errors?: any[];
  awsKey?: string;
  skipped?: boolean;
  uploadId?: number;
}

export class MySubClassedDexie extends Dexie {
  pictures!: Table<IPicture>;

  constructor() {
    super('picture-database-v2');
    this.version(7).stores({
      pictures: `++id, roomId`,
    });
  }
}

export const handleError = (error: any): void => {
  switch (error.name) {
    case 'AbortError':
      Sentry.addBreadcrumb({ message: 'AbortError', level: 'error' });
      if (error.inner) {
        return handleError(error.inner);
      }
      Sentry.captureException(error);
      break;
    case 'QuotaExceededError':
      Sentry.addBreadcrumb({ message: 'QuotaExceededError', level: 'error' });
      Sentry.captureException(error);
      break;
    default:
      Sentry.captureException(error);
      break;
  }
};

export const validateIndexedDBFields = (data: any) => {
  // Define the required fields
  const requiredFields = ['id', 'content', 'roomId', 'imgType', 'uploadId'];

  // Check if all required fields are present in each object
  return data.every((item: IPicture) =>
    requiredFields.every((field) => item.hasOwnProperty(field))
  );
};

export const imagesUploaded = (images: IPicture[]): boolean => {
  return images.every((image) => image.uploaded);
};

export const dexieDb = new MySubClassedDexie();
