import ContentView from 'src/components/Content/Content';
import Text from 'src/components/Text/Text';
import Footer from 'src/components/Footer/Footer';
import BaseButton from 'src/components/BaseButton/BaseButton';
import BrokenGears from 'src/components/icons/common/BrokenGears';

import { useNavigate } from 'react-router';
import { useEntityConfigStore } from 'src/stores/entityConfig';

export const GenericError = () => {
  const navigate = useNavigate();
  const entityConfig = useEntityConfigStore((state) => state.config);

  return (
    <ContentView
      variant="secondary"
      className="min-h-screen -mt-14 landscape:pb-24"
    >
      <div className="w-1/2 pb-3 mt-14">
        <BrokenGears className="fill-icons-color" />
      </div>
      <div className="px-4">
        <div className="w-full desktops:w-desktop desktops:px-0 max-w-desktop pb-8">
          <div className="pb-3">
            <Text
              variant="title"
              capFirstLetterOnly={entityConfig.capFirstLetterOnly}
            >
              an error just happened!
            </Text>
          </div>
          <Text variant="subtitle">
            Please try again by refreshing the page in the button below. If you
            are not able to proceed please reach out to Hosta A.I support.
          </Text>
        </div>
      </div>
      <Footer>
        <BaseButton
          dataTestId="start-room-capture-btn"
          onClick={() => navigate(0)}
          label="refresh to continue"
          capFirstLetterOnly={entityConfig.capFirstLetterOnly}
        />
      </Footer>
    </ContentView>
  );
};

export default GenericError;
