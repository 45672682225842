import { dexieDb } from 'src/utils/indexedDb';
import { useNavigate } from 'react-router-dom';
import SkipButtonComp from 'src/components/SkipButton/SkipButton';

import type { IPicture } from 'src/utils/indexedDb';
import { useFeatureStore } from 'src/stores/featureStore';

type Image = {
  id: number;
  count: number;
};

type ImageSet = {
  [key: string]: Image;
};

const SkipButton = () => {
  const navigate = useNavigate();
  const isTipsVisible = useFeatureStore((state) => state.tipsVisible);

  const groupImagesByDamageId = (images: IPicture[]) => {
    return images.reduce((images: ImageSet, item) => {
      const currentDamageId = item.damageId || 0;
      images[currentDamageId] = {
        id: item.id,
        count: (images?.[currentDamageId]?.count || 0) + 1,
      };

      return images;
    }, {});
  };

  const handleSkip = async () => {
    const damageImages = await dexieDb.pictures.toArray();
    const groupedImages = groupImagesByDamageId(damageImages);
    const keys = Object.values(groupedImages)
      .filter((item) => item.count < 2)
      .map((item) => item.id);

    await dexieDb.pictures.bulkDelete(keys);
    if (!isTipsVisible) navigate('../capture-room');
    else navigate('../tips');
  };

  return (
    <SkipButtonComp
      testId="skip-damages-btn"
      text="skip damages"
      onClick={handleSkip}
    />
  );
};

export default SkipButton;
